import {gql} from '@apollo/client/core';

export const CommonQueries = {
    gql: {
        odstavka: gql`
            query VyjadrovaciLinkaOdstavkaQuery {
                vyjadrovaciLinka {
                    odstavka {
                        aktivni
                        formZakazanHtml
                        id
                        typFormulare
                    }
                }
            }
        `,
        profil: gql`
            query VyjadrovaciLinkaProfilQuery {
                me {
                    vyjadrovaciLinkaProfil {
                        adresa {
                            ulice
                            cisloEvidencni
                            cisloOrientacni
                            cisloPopisne
                            obec
                            mistniCast
                            psc
                            stat
                        }
                        email
                        ico
                        jmeno
                        nazevFirmy
                        prijmeni
                        subjekt
                        telefon
                        titulPred
                    }
                    ucet {
                        id
                        jmeno
                        prijmeni
                    }
                }
            }
        `,
        ares: gql`
            query Ares($ic: String!) {
                ares {
                    companyDataByIco(ic: $ic) {
                        nazevFirmy
                        adresa {
                            ulice
                            cisloEvidencni
                            cisloOrientacni
                            cisloPopisne
                            obec
                            mistniCast
                            psc
                        }
                    }
                }
            }
        `,
        om: gql`
            query NaseptavacOdbernaMista($input: String!, $parseId: String!) {
                adresniMista {
                    fulltextAdresniMista(
                        first: 5
                        filter: {adresa: $input, fulltextFields: [ulice, cisloEvidencni, cisloOrientacni, cisloPopisne, psc, mesto, mistniCast]}
                        parseId: $parseId
                    ) {
                        edges {
                            node {
                                id
                                cisloEvidencni
                                ulice
                                cisloOrientacni
                                cisloPopisne
                                mesto
                                mistniCast
                                psc
                            }
                        }
                    }
                }
            }
        `,
        geoportalRedirectUri: gql`
            query GeoportalRedirectUri($id: MongoObjectId!) {
                vyjadrovacky {
                    geoPortalRedirectUri(id: $id)
                }
            }
        `,
        geoportalRedirectUriByTid: gql`
            query geoportalRedirectUriByTid($tid: String!) {
                vyjadrovacky {
                    geoPortalRedirectUriByTid(tid: $tid)
                }
            }
        `,
        vyjadrovacka: gql`
            query Vyjadrovacka($tid: String!) {
                vyjadrovacky {
                    zadostByTid(tid: $tid) {
                        id
                        tid
                        lzePrekreslitMawis
                        investor {
                            adresa {
                                ulice
                                cisloEvidencni
                                cisloOrientacni
                                cisloPopisne
                                obec
                                mistniCast
                                psc
                            }
                            titulPred
                            titulZa
                            datumNarozeni
                            dic
                            email
                            ico
                            jmeno
                            nazevFirmy
                            prijmeni
                            subjekt
                            telefon
                        }
                        technickaSpecifikace {
                            digitalniDokumentace
                            elektrina
                            plyn
                            poznamka
                            udajeOStavbe
                        }
                        zadatel {
                            adresa {
                                ulice
                                cisloEvidencni
                                cisloOrientacni
                                cisloPopisne
                                obec
                                mistniCast
                                psc
                            }
                            titulPred
                            titulZa
                            dic
                            email
                            ico
                            jmeno
                            nazevFirmy
                            prijmeni
                            subjekt
                            telefon
                        }
                        zadatelJeInvestor
                    }
                }
            }
        `,
        prekresleni: gql`
            query Vyjadrovacka($tid: String!) {
                vyjadrovacky {
                    zadostByTid(tid: $tid) {
                        id
                        tid
                        lzePrekreslitMawis
                    }
                }
            }
        `,
    },
};
